#root {
  height: 100%;
}
#login .login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 40px;
  width: 400px;
}

#login .login-form-button {
  width: 100%;
}
.user_information {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 8px;
}
.user_information .setting_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.business_box {
  padding: 10px 30px;
}
.Income-Content {
  padding: 30px;
}
.plat-Content-box {
  padding: 30px 0;
  position: relative;
}
.each-Content-plat-box {
  height: 100%;
  width: 100%;
}
.each-Content-plat-box .account-list-box {
  padding: 30px 0;
}
.plat-Content-box .each-plat {
  border: 1px solid #ccc;
  width: 200px;
  height: 100px;
  border-radius: 5px;
}
